<template>
  <div>
    <v-row>
      <v-col sm="6">
        <v-card outlined rounded>
          <v-card-title> {{ $t('menu.sprgroup') }} </v-card-title>
          <v-card-text>
            <v-list dense>
              <v-list-item-group color="primary">
                <v-list-item @click="link('user')">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t('menu.user') }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item @click="link('adr')">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t('menu.adr') }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item @click="link('price')">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t('menu.price') }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item @click="link('status')">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t('menu.status') }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item @click="link('print')">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t('menu.tpl') }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item @click="link('jobfield')">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t('menu.jobfield') }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item @click="link('customerfield')">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t('menu.customerfield') }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col sm="6">
        <v-card outlined rounded>
          <v-card-title> {{ $t('menu.sttgroup') }} </v-card-title>
          <v-card-text>
            <v-list dense>
              <v-list-item-group color="primary">
                <v-list-item @click="link('salary')">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t('menu.sttsalary') }}</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-list-item @click="link('setting')">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t('menu.setting') }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  computed: {},
  components: {},
  watch: {},
  mounted: function () {},
  methods: {
    link: function (view) {
      this.$router.push(view)
    },
  },
}
</script>

<style></style>
